import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box, Slide } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@material-ui/lab";
import useBonds from "../../hooks/Bonds";
import { redeemBond, redeemAllBonds } from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { trim, secondsUntilBlock, prettifySeconds, prettyVestingPeriod } from "../../helpers";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import BondLogo from "../../components/BondLogo";

function BondRedeem({ bond }) {
  // const { bond: bondName } = bond;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bonds } = useBonds();
  const { provider, address, chainID } = useWeb3Context();
  const isSmallScreen = useMediaQuery("(max-width: 500px)");

  const isBondLoading = useSelector(state => state.bonding.loading ?? true);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  const bondingState = useSelector(state => {
    return state.bonding && state.bonding[bond.name];
  });
  // const bondDetails = useSelector(state => {
  //   return state.account.bonds && state.account.bonds[bond.name];
  // });

  async function onRedeem({ autostake, bond }) {
    await dispatch(redeemBond({ address, bond, networkID: chainID, provider, autostake }));
  }

  async function onRedeemReward({ autostake }) {
    await dispatch(redeemAllBonds({ address, bonds, networkID: chainID, provider, autostake }));
  }

  const vestingTime = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bondingState ? bondingState.vestingTerm : '');
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" className="bond-border-container">

      {!isSmallScreen && bonds.map((bond, index) => (
        <div className="redeem-table-container flex flex-justify-content-between flex-align-items-center" style={{width: '100%'}}>
        <div className="bond-redeem-table">
          <div className="bond-table-header">
            <div className="header-25">{bond.name}</div>
            <div className="header-25">{t('bond.claimable')}</div>
            <div className="header-25">{t('bond.pending')}</div>
            <div className="header-25">{t('bond.fullyVested')}</div>
            {/* {isSmallScreen ? <div className="header-price"></div> : null} */}
          </div>

          <div className="bond-table-body">
            <div className={`body-item`}>
              <div className="body-item-25">
                <BondLogo bond={bond} />
              </div>
              <div className="body-item-25">
                {!address ? '0' : bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton width={60} style={{ margin: '0 auto' }} />}
              </div>
              <div className="body-item-25">
                {!address ? '0' : bond.interestDue || bond.interestDue === 0 ? trim(bond.interestDue, 4) : <Skeleton width={60} style={{ margin: '0 auto' }} />}
              </div>
              <div className="body-item-25">
                {!address ? '0 Day' : !vestingTime(bond) && vestingTime(bond) !== '' ? <Skeleton width={60} style={{ margin: '0 auto' }} /> : vestingTime(bond) === '' ? `0 ${t('common.d')}` : vestingTime(bond)}
              </div>

              {/* {isSmallScreen ? <div className="body-item-price">
                <Button
                  variant="outlined"
                  color="primary"
                  id="bond-detail-btn"
                  className="transaction-button"
                  onClick={() => 
                    handleBond(index)
                  }
                >
                  {t('bond.bond')}
                </Button>
              </div> : null} */}
            </div>
          </div>
        </div>

        <div className="flex" style={{ marginTop: isSmallScreen ? '20px' : ''}}>
          <Button
            variant="outlined"
            color="primary"
            className="redeem-button"
            fullWidth
            style={{marginLeft: 40, marginRight: 20}}
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name)}
            onClick={() => {
              onRedeem({ autostake: false, bond });
            }}
          >
            {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name, t('bond.claim'))}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="redeem-button"
            fullWidth
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name + "_autostake")}
            onClick={() => {
              onRedeem({ autostake: true, bond });
            }}
          >
            {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name + "_autostake", t('bond.autostake'))}
          </Button>
        </div>
        </div>
      ))}

      {isSmallScreen && bonds.map((bond, index) => (
        <div className="redeem-table-container flex flex-justify-content-between flex-align-items-center" style={{width: '100%'}}>

        <div className="bond-redeem-table" style={{width: '100%', marginBottom: 20}}>
          <div className="bond-table-header">
            <div className="width100">{bond.name}</div>
          </div>
          <div className="bond-table-body">
            <div className="body-item">
            <div className="body-item-25">
                <BondLogo bond={bond} />
              </div>
            </div>
          </div>
        </div>

        <div className="bond-redeem-table" style={{width: '100%'}}>
          <div className="bond-table-header">
            <div className="header-33">{t('bond.claimable')}</div>
            <div className="header-33">{t('bond.pending')}</div>
            <div className="header-33">{t('bond.fullyVested')}</div>
            {/* {isSmallScreen ? <div className="header-price"></div> : null} */}
          </div>

          <div className="bond-table-body">
            <div className={`body-item`}>
              <div className="body-item-33">
                {!address ? '0' : bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton width={60} style={{ margin: '0 auto' }} />}
              </div>
              <div className="body-item-33">
                {!address ? '0' : bond.interestDue || bond.interestDue === 0 ? trim(bond.interestDue, 4) : <Skeleton width={60} style={{ margin: '0 auto' }} />}
              </div>
              <div className="body-item-33">
                {!address ? '0 Day' : !vestingTime(bond) && vestingTime(bond) !== '' ? <Skeleton width={60} style={{ margin: '0 auto' }} /> : vestingTime(bond) === '' ? `0 ${t('common.d')}` : vestingTime(bond)}
              </div>

              {/* {isSmallScreen ? <div className="body-item-price">
                <Button
                  variant="outlined"
                  color="primary"
                  id="bond-detail-btn"
                  className="transaction-button"
                  onClick={() => 
                    handleBond(index)
                  }
                >
                  {t('bond.bond')}
                </Button>
              </div> : null} */}
            </div>
          </div>
        </div>

        <div className={`flex ${isSmallScreen ? 'flex-justify-content-around width100' : ''}`} style={{ marginTop: isSmallScreen ? '20px' : ''}}>
          <Button
            variant="outlined"
            color="primary"
            className="redeem-button"
            // fullWidth
            style={{marginLeft: isSmallScreen ? 0 : 40, marginRight: isSmallScreen ? 8 : 20}}
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name)}
            onClick={() => {
              onRedeem({ autostake: false, bond });
            }}
          >
            {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name, t('bond.claim'))}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="redeem-button"
            // fullWidth
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name + "_autostake")}
            onClick={() => {
              onRedeem({ autostake: true, bond });
            }}
          >
            {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name + "_autostake", t('bond.autostake'))}
          </Button>
        </div>
        </div>
      ))}

      <Box display="flex" justifyContent="space-around" className={`width100 ${isSmallScreen ? 'flex-wrap' : ''}`} style={{marginTop: 10}}>
        <Button
          variant="outlined"
          color="primary"
          id="bond-claim-btn"
          className="redeem-button"
          // fullWidth
          disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name)}
          onClick={() => {
            onRedeemReward({ autostake: false });
          }}
        >
          {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name, t('bond.claimAll'))}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          // id="bond-claim-autostake-btn"
          className="redeem-button"
          // fullWidth
          disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name + "_autostake")}
          onClick={() => {
            onRedeemReward({ autostake: true });
          }}
        >
          {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name + "_autostake", t('bond.claimAllAuto'))}
        </Button>
      </Box>

      {/* <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 553 }}>
        <Box className="bond-data">
          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.pendingReward')}</div>

            <div className="bond-value">
              {!bond.interestDue && bond.interestDue !== 0 ? <Skeleton width="100px" /> : `${trim(bond.interestDue, 4)} MOS`}
            </div>
          </div>
          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.claimReward')}</div>

            <div className="bond-value">
              {!address ? '0 MOS' : !bond.pendingPayout && bond.pendingPayout !== 0 ? <Skeleton width="100px" /> : `${trim(bond.pendingPayout, 4)} MOS`}
            </div>
          </div>
          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.timeUntil')}</div>

            <div className="bond-value">
              {!address ? '0 day' : !vestingTime() && vestingTime() !== 0 ? <Skeleton width="100px" /> : vestingTime()}
            </div>
          </div>

          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.ROI')}</div>

            <div className="bond-value">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondDiscount * 100, 2)}%`}
            </div>
          </div>

          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.debt')}</div>

            <div className="bond-value">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.debtRatio / 10000000, 2)}%`}
            </div>
          </div>

          <div className="data-row data-row-bg">
            <div className="bond-label">{t('bond.vesting')}</div>

            <div className="bond-value">
              {isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}
            </div>
          </div>
        </Box>
      </Slide> */}
    </Box>
  );
}

export default BondRedeem;
