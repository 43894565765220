import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from '@material-ui/core/CircularProgress'
import { Skeleton } from "@material-ui/lab";
import { ethers } from "ethers";
import {
	Box,
	Grid,
	Paper,
	Typography,
	InputAdornment,
	Input,
	Button
} from "@material-ui/core";
import { useWeb3Context } from "../../hooks/web3Context";
import { useTranslation } from 'react-i18next';
import { abi as aSOHM } from "../../abi/aSOHM.json";
import { abi as aSOHMMigration } from "../../abi/aSOHMMigration.json";
import { error } from "../../slices/MessagesSlice";
import { getBalances } from "../../slices/AccountSlice";
import { addresses } from "src/constants";
import "./ido.scss";
import "./ido.app.scss";
import useIdo from "src/hooks/useIdo";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import USDT from '../../assets/usdt.png'
import MOS from '../../assets/mod.png'
import Swap from '../../assets/swap.png'
import Yes from '../../assets/yes.png'
import No from '../../assets/no.png'
import { formatNum } from '../../utils';
import { trim } from "../../helpers";

function Ido() {
  const { t } = useTranslation();
	const isSmallScreen = useMediaQuery("(max-width: 500px)")
	const { provider, chainID, address, connect } = useWeb3Context();

	const dispatch = useDispatch();
	const { goal, white, participated, allowance, alloction, iceBalance, isApproving, DoClaim, DoApprove, getTokenBalance } = useIdo()
	const [pending, setPending] = useState(false)
	const [tokenBalance, setTokenBalance] = useState()
	const [progressValue, setProgressValue] = useState(0)
	const [usdtValue, setUsdtValue] = useState(1000)
	const [mosValue, setMosValue] = useState(125)
	

	const pendingTransactions = useSelector(state => {
		return state.pendingTransactions;
	});

	useEffect(() => {
		if (address && chainID) {
			getTokenBalance(addresses[chainID].USDT_ADDRESS).then(bal => {
				bal && setTokenBalance(bal)
			}).catch(e => { console.log(e) })
		}
	}, [address, provider, chainID])

	useEffect(() => {
		if(alloction && goal && alloction !== 0 && goal !== 0) {
			const value = ethers.utils.formatEther(alloction) / ethers.utils.formatEther(goal) * 100
			setProgressValue(value.toFixed(2))
		}
	}, [alloction, goal])

	const buy = async () => {
		let tx1
		if (allowance > 0) {
			DoClaim(Number(usdtValue)).then(tx => {
				tx1 = tx
				setPending(false)
			})
		} else {
			DoApprove().then(tx => {
				tx1 = tx
				setPending(false)
			})
		}
	}

	const usdtChange = (e) => {
		const { value } = e.target
		const inputValue = value.replace(/\D/g, '')
		setUsdtValue(inputValue)
		setMosValue((Number(inputValue) / 8).toFixed(2))
	}

	const inputBlur = () => {
		const max = 1000
		const min = 500

		if(Number(usdtValue) <= 500) {
			setUsdtValue(min)
			setMosValue((min / 8).toFixed(2))
		}

		if(Number(usdtValue) >= 1000) {
			setUsdtValue(max)
			setMosValue((max / 8).toFixed(2))
		}
	}

	return (
		<div className="ido-info-view">
			<div className="ido-top">
				<div className="ido-top-content">
					<div className="ido-top-content-left">
						<p>{t('ido.ido')}</p>
						<div className="white-not flex flex-justify-content-start">
							<div className="whitelist flex flex-align-items-center">
								{white ? <img src={Yes} alt="" /> : <img src={No} alt="" />}
								<div>{t('ido.whitelist')}</div>
							</div>
							<div className="flex flex-align-items-center">
								{participated ? <img src={Yes} alt="" /> : <img src={No} alt="" />}
								<div>{t('ido.participated')}</div>
							</div>
						</div>
					</div>
					<div className="ido-top-content-right">
						<p className="top-label">{t('ido.fundraGoal')}</p>
						{/* <p className="top-value">$ 500,000</p> */}
						<p className="top-value">
							{
								goal ?
								goal.toString() === '0' ? '0' : formatNum(trim(ethers.utils.formatEther(goal))) + ' USDT'
								:
								<Skeleton />
							}
						</p>
					</div>
				</div>
			</div>

			<div className="ido-bot">
				<div className="ido-bot-left">
					<div className="bot-left-top">
						<div className="ido-usdt">
							<div className="ido-label">USDT</div>
							<div className="ido-value">8 USDT</div>
							<img src={USDT} alt="" />
						</div>
						<img className="swap-icon" src={Swap} alt="" />
						<div className="ido-mod">
							<div className="ido-label">MOS</div>
							<div className="ido-value">1 MOS</div>
							<img src={MOS} alt="" />
						</div>
					</div>

					<div className="bot-left-mid flex flex-justify-content-between flex-align-items-center flex-column">
						{/* <div className="left-mid-usdt">$1,000</div> */}
						<Input
							className="usdt-input"
						  labelWidth={0}
							value={usdtValue}
							onChange={usdtChange}
							onBlur={inputBlur}
							startAdornment={
								<InputAdornment position="start">
									<div>
										$
									</div>
								</InputAdornment>
							}
						/>
						<div className="left-mid-mod">{mosValue} MOS</div>
					</div>

					<div className="bot-left-bot">
						<Button
              variant="outlined"
              color="primary"
							loading={pending}
							onClick={() => {
								setPending(true)
								buy()
							}}
              className="confirmBtn transaction-button"
							disabled={participated}
							// className="confirmBtn mt-24"
							// style={{
							// 	backgroundColor: white && !participated ? '#00FFAB' : '#323232',
							// 	color: white && !participated ? '#333' : '#8A8A8A',
							// }}
							block={true}>
								{pending ? <CircularProgress /> : (allowance > 0 ? 'CONFIRM' : t('ido.approve'))}
						</Button>

						{/* <div className="left-bot-btn flex flex-justify-content-center flex-align-items-center">{t('ido.approve')}</div> */}
					</div>
				</div>
				<div className="ido-bot-right">
					<div className="bot-right-item flex flex-justify-content-between flex-align-items-center">
						<div>{t('ido.balance')}</div>
						<div>
							{tokenBalance ? trim(ethers.utils.formatEther(tokenBalance), 2) : 0} USDT
						</div>
					</div>

					<div className="bot-right-item flex flex-justify-content-between flex-align-items-center">
						<div>{t('ido.progress')}</div>
						<div>
							{/* {progressValue}% */}
							{alloction ? trim(ethers.utils.formatEther(alloction), 2) : 0} USDT
						</div>
					</div>

					<div className="bot-right-item flex flex-justify-content-between flex-align-items-center">
						<div>{t('ido.idoPrice')}</div>
						<div>8 USDT</div>
					</div>

					<div className="bot-right-item flex flex-justify-content-between flex-align-items-center">
						<div>{t('ido.launchPrice')}</div>
						<div style={{color: '#38A169'}}>12 USDT</div>
					</div>

					<div className="ido-date">
						{/* Sale 20 May, 12:00 – 21 May, 12:00 UTC */}
						{t('ido.date')}
					</div>
				</div>
			</div>
		</div>
	)
}
export default Ido