import 'antd/dist/antd.css';

import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";

import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails, loadGovernanceDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, calculateUserSuperBondDetails } from "./slices/AccountSlice";

import { ChooseBond, TreasuryDashboard, Dashboard } from "./views";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import LoadingSplash from "./components/Loading/LoadingSplash";
import Messages from "./components/Messages/Messages";
import NotFound from "./views/404/NotFound";
import { ethers } from "ethers"
import { dark as darkTheme } from "./themes/dark.js";

import i18n from './react-i18next-config'

import "./style.scss";

const DEBUG = false;
const Ido = lazy(() => import('./views/Ido/Ido'));
const Snowglobe = lazy(() => import('./views/Snowglobe/Snowglobe'));
const Stake = lazy(() => import('./views/Stake/Stake'));
const Bond = lazy(() => import('./views/Bond/Bond'));
const BondDetail = lazy(() => import('./views/Bond/BondDetail'));
const Invite = lazy(() => import('./views/Invite/Invite'));
// const Governance = lazy(() => import('./views/governance/Governance'));
const TokenSwap = lazy(() => import('./views/TokenSwap/TokenSwap'));
const Redeem = lazy(() => import('./views/Redeem/Redeem'));
const GenesisChain = lazy(() => import('./views/GenesisChain/GenesisChain'))
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

const drawerWidth = 280;
const transitionDuration = 969;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: transitionDuration,
    }),
    height: "100%",
    overflow: "auto",
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginLeft: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function App() {
  // useGoogleAnalytics();
  const dispatch = useDispatch();
  const [theme, toggleTheme] = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [showPerformance, setShowPerformance] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 958px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const address = useAddress();

  const [walletChecked, setWalletChecked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { bonds } = useBonds();
  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }
    if (whichDetails === "account" && address && connected && isLoad) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      dispatch(loadGovernanceDetails({ networkID: chainID, provider: loadProvider }));
      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: null, provider: loadProvider, networkID: chainID, address }));
      });
      setIsLoad(true)
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(calculateUserSuperBondDetails({ address, bond, provider, networkID: chainID }));
      });
    },
    [connected],
  );

  const switchPerformance = () => {
    setShowPerformance(!showPerformance)
  }

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    storeQueryParameters();
  }, []);

  useEffect(() => {
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    if (connected && isLoad) {
      loadDetails("account");
    }
  }, [connected, isLoad]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  let themeMode = darkTheme;

  useEffect(() => {
    themeMode = darkTheme;
  }, [theme]);

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
  }, [location]);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <div className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
        <Messages />
        <TopBar showPerformance={showPerformance} theme={theme} switchPerformance={switchPerformance} toggleTheme={toggleTheme} handleDrawerToggle={handleDrawerToggle} />
        <nav className={classes.drawer}>
          <Hidden mdUp >
            <NavDrawer theme={theme} showPerformance={showPerformance} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          </Hidden>
          <Hidden smDown>
            <Sidebar theme={theme} showPerformance={showPerformance} />
          </Hidden>
        </nav>

        <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/ido" component={Ido} />
              <Route path="/invite" component={Invite} />
              {isSmallScreen ? <Route path="/bond-detail" component={BondDetail} /> : null}

              <Route exact path="/dashboard">
                <Dashboard showPerformance={showPerformance} />
              </Route>

              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              <Route path="/stake">
                <Stake theme={theme} />
              </Route>
              <Route exact path="/snowglobe">
                <Snowglobe />
              </Route>
              <Route exact path="/tokenSwap">
                <TokenSwap />
              </Route>
              <Route path="/genesis" component={GenesisChain} />

              <Route path="/bonds">
                {/* {bonds.map(bond => {
                  return (
                    <Route exact key={bond.name} path={`/bonds/${bond.name}`} >
                      <Bond theme={theme} bond={bond} />
                    </Route>
                    );
                })} */}
                <ChooseBond bond={bonds[0]} />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
        {/* <img src={leftIcon} className="leftIcon"></img>
        <img src={rightIcon} className="rightIcon"></img> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
