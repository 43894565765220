import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'antd'
import { Skeleton } from "@material-ui/lab";
import { allBondsMap } from "src/helpers/AllBonds";
import useInvite from '../../hooks/useInvite';
import { getQueryString } from '../../utils'
import { openWarningNotification } from '../../utils/tip.js';
import { trim } from "../../helpers";
import {list} from '../../config/black'
import { useTranslation } from 'react-i18next';
import { useAddress } from "src/hooks/web3Context";

import USDT from '../../assets/usdtIcon.png'
import Mos from '../../assets/morganIcon.png'

import "./dashboard.scss";
import "./dashboard.app.scss";


function Dashboard({showPerformance}) {
  const { t } = useTranslation();
  // Use marketPrice as indicator of loading.
  const dispatch = useDispatch();
	const {pathname} = useLocation()
  const address = useAddress();
  const { nodeAddress, gcAddress, total, group, AcceptInvite } = useInvite()
	const [inviteAddress, setInviteAddress] = useState()
	const [visible, setVisible] = useState(false)
	const [invitePending, setInvitePending] = useState(false)
  // const [bondReward, setBondReward] = useState(0)
  // const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);

  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm;
  });
  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      let bondRewards = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
          bondRewards += state.bonding[bond].interestDue
        }
      }
      return {
        tokenBalances,
        bondRewards
      };
    }
    return {
      tokenBalances: 0,
      bondRewards: 0
    };
  });
  // const bondReward = useSelector(state => {
  //   if (state.bonding.loading == false) {
  //     let bondRewards = 0;
  //     for (const bond in allBondsMap) {
  //       if (state.bonding[bond]) {
  //         bondRewards += state.bonding[bond].interestDue
  //       }
  //     }
  //     return bondRewards;
  //   }
  // });
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });
  const DAOBalance = useSelector(state => {
    return state.app.DAOBalance;
  });
  const feeBalance = useSelector(state => {
    return state.app.FeeBalance;
  });
  const warmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.warmBalance;
  });

  useEffect(() => {
		const address = getQueryString('address')
		if(address) {
			setInviteAddress(address)
			setVisible(true)
		}
	}, [pathname, address])

  const handleCloseModal = () => {
		setVisible(false)
	}

	const acceptInvite = () => {
		if(address) {
      if(address === inviteAddress) {
        openWarningNotification("Same Address")
        setVisible(false)
        return
      }
			setInvitePending(true)
			AcceptInvite(inviteAddress).then(tx => {
				setInvitePending(false)
				if(tx) {
					setVisible(false)
					window.location.href = window.location.href.split('?')[0]
				}
			})
		} else {
			openWarningNotification('Connect Wallet')
		}
	}

  return (
    <div className="dashboard-view">
      <div className="dashboard-top">
        <div className="top-head flex flex-justify-content-between">
          <div className="head-text">{t('dashboard.dashboard')}</div>
          <div className="head-btn flex flex-justify-content-center flex-align-items-center">Buy MOS</div>
        </div>

        <div className="top-content flex flex-justify-content-around">
          <div className="top-content-item flex flex-column flex-align-items-center">
            <div className="top-content-value-content flex flex-align-items-center">
              <span className="top-content-unit">$ </span>
              {
                !address ?
                  <span className="top-content-value">0</span>
                :
                  ohmBalance || ohmBalance === 0 ?
                  (
                    <span className="top-content-value">{trim(ohmBalance, 4)}</span>
                  ) : (
                    <span className="top-content-value">
                      <Skeleton width="100px" height="40px" />
                    </span>
                  )
              }
              <img className="top-content-img" src={Mos} alt="" />
            </div>
            <div className="top-content-label">{t('dashboard.balance')}</div>
          </div>

          <div className="top-content-btn flex flex-justify-content-center flex-align-items-center">Buy MOS</div>

          <div className="top-content-item flex flex-column flex-align-items-center">
            <div className="top-content-value-content flex flex-align-items-center">
              <span className="top-content-unit">$ </span>
              {
                marketPrice || marketPrice === 0 ?
                  (
                    <span className="top-content-value">{trim(marketPrice, 4)}</span>
                  ) : (
                    <span className="top-content-value">
                      <Skeleton width="100px" height="40px" />
                    </span>
                  )
              }
              <img className="top-content-img" src={USDT} alt="" />
            </div>
            <div className="top-content-label">{t('dashboard.marketPrice')}</div>
          </div>
        </div>
      </div>

      <div className="dashboard-bot flex flex-justify-content-between">
        <div className="bot-left flex flex-justify-content-between">
          <div className="bot-left-left">
            <div className="bot-left-item">
              <div className="bot-left-label">{t('dashboard.staked')}</div>
              <div className="bot-left-value">
                {
                  !address ? '0 sMOS'
                  :
                    sohmBalance || sohmBalance === 0 ?
                    (
                      trim(sohmBalance, 2) + ' sMOS'
                    ) : (
                      <Skeleton />
                    )
                }
              </div>
            </div>

            <div className="bot-left-item">
              <div className="bot-left-label">{t('dashboard.index')}</div>
              <div className="bot-left-value">
                {
                  currentIndex || currentIndex === 0
                  ?
                  (Number(currentIndex.toString())).toFixed(2) + ' MOS'
                  : 
                  <Skeleton width="150px" />
                }
              </div>
            </div>

            <div className="bot-left-item">
              <div className="bot-left-label">{t('dashboard.treasuryBalance')}</div>
              <div className="bot-left-value">
                {
                  treasuryBalance.tokenBalances || treasuryBalance.tokenBalances === 0
                  ?
                  `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance.tokenBalances))}`
                  :
                  <Skeleton width="150px" />
                }
              </div>
            </div>
          </div>
          <div className="bot-left-right">
            <div style={{marginBottom: 28}}>
              <div className="bot-left-label">{t('dashboard.warmupBalance')}</div>
              <div className="bot-left-value">
                {!address ? '0 sMOS' : !warmBalance && warmBalance !== 0 ? <Skeleton width="80px" /> :
                  <>
                    {trim(warmBalance, 2)} sMOS
                  </>}
              </div>
            </div>

            <div>
              <div className="bot-left-label">{t('dashboard.bondRewards')}</div>
              <div className="bot-left-value">{treasuryBalance.bondRewards ? treasuryBalance.bondRewards.toFixed(2) : 0} MOS</div>
            </div>
          </div>
        </div>
        <div className="bot-right flex flex-justify-content-between flex-column">
          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.totalValueLocked')}</div>
            <div className="item-amount">
              {stakingTVL || stakingTVL === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(stakingTVL)
                ) : (
                  <Skeleton width="150px" />
                )}
            </div>
          </div>

          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.circulatingMarketCap')}</div>
            <div className="item-amount">
              {marketCap || marketCap === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(marketCap)
                ) : (
                  <Skeleton width="150px" />
                )}
            </div>
          </div>

          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.dao')}</div>
            <div className="item-amount">
              {DAOBalance || DAOBalance === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(DAOBalance) + ' MOS'
                ) : (
                  <Skeleton width="150px" />
                )}
            </div>
          </div>

          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.settleFee')}</div>
            <div className="item-amount">
              {feeBalance || feeBalance === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(feeBalance) + ' MOS'
                ) : (
                  <Skeleton width="150px" />
                )}
            </div>
          </div>
        </div>
      </div>

      {/*
      <Modal visible={visible} centered={true} footer={null} onCancel={handleCloseModal}>
				<div className="invite-modal">
					<div className="invite-modal-title">Notice</div>
					<div className="flex flex-justify-content-center flex-align-items-center invite-modal-content">Please confirm the binding invitation relationship</div>
					<div className="flex flex-justify-content-center">
						<Button className="buyBtn" loading={invitePending} onClick={() => acceptInvite()}>Yes</Button>
					</div>
				</div>
			</Modal> */}
    </div>
  );
}

export default Dashboard;
