import { Button, Link } from "@material-ui/core";
import copy from 'copy-to-clipboard';
import { useWeb3Context } from '../../hooks/web3Context'
import { openSuccessNotification } from '../../utils/tip.js';
import { useTranslation } from 'react-i18next';

import Telegram from "../../assets/telegram.png";
import Github from "../../assets/github.png";
import Twitter from "../../assets/twitter.png";
import Medium from "../../assets/medium.png";
import InviteAddress from "../../assets/invite-address.png";

export default function Social() {

  const { t } = useTranslation()
	const { address } = useWeb3Context()

  const copyAddress = () => {
    const href = window.location.origin + window.location.pathname
    copy(`${href}?address=${address}`)
    openSuccessNotification(t('tip.copySucceed'))
  }

  return (
    <div>
      {address ? <div style={{ marginBottom: 30}}>
        <div className="invite-address-btn flex flex-justify-content-center flex-align-items-center" onClick={copyAddress}><img src={InviteAddress} alt="" />{t('invite.inviteAdd')}</div>
        {/* <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            copyAddress()
          }}
          className="copyBtn transaction-button"
          block={true}
        >
          Invite Address
        </Button> */}
      </div> : null}

      <div className="social-row" >
        <Link href="https://twitter.com/MorganDAO_"  target="_blank">
          <img src={Twitter} alt="" />
        </Link>

        <Link href="https://t.me/MorganDao"  target="_blank">
          <img src={Telegram} alt="" />
        </Link>

        <Link href="https://github.com/MorganDAO/" target="_blank">
          <img src={Github} alt="" />
        </Link>
        <Link href="https://medium.com/@morgandao.money" target="_blank">
          <img src={Medium} alt="" />
        </Link>
      </div>
    </div>
  );
}
