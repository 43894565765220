// NOTE could get this from an outside source since it changes slightly over time
// export const BLOCK_RATE_SECONDS = 0.9; //FTM
export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    OHM_ADDRESS: "0x986d88736CC8c18069B75a3023EC3880f3b20562", //
    SOHM_ADDRESS: "0x6605Bb831bd024E6b0862522873B1d4352926817",
    STAKING_ADDRESS: "0x48112F6475A50C72BDd23EB7f63c3A83B32bB1D3",
    STAKING_HELPER_ADDRESS: "0x450628244ec24a1aACD1714F42E9e15C801177ff",
    DISTRIBUTOR_ADDRESS: "0x3aEB0c49Eb3ee143c708365399079a97698f51d0",
    BONDINGCALC_ADDRESS: "0xC6F398a545c6CbF18d8E443D80B70284cE846EF2",
    TREASURY_ADDRESS: "0xC0908Bd62618bF499D7CECDB37dEe71f8DEF94F6",
    DAO_ADDRESS: '0xA067054d7Ef642d4c0Da0fd7D2D6ab37DbC2A4FD',
    LP_ADDRESS: '0x1d8E780d89b4BdBcab3cE719C47C47cf1e3490A5',
    REDEEM_HELPER_ADDRESS: "0xB8AfDb5d735d6e38773A951035504659a297Ef17",
    MIGRATION_ADDRESS:'0x4B80F688161b4880b4Fe001b406C4017DD0Ea80c',
    TEAM_ADDRESS:'0x6105057b75953fCf0803b01002730c7E3b3C54da',
    TEAM_AMOUNT_ADDRESS:'0x32B2b36E8Db1FD1DdE818229bCb4d19174979Bc9',

    INVITE_ADDRESS: "0x68a8ebeca34EF83aaa43B8c186EBA534C0317FF2",
    USDT_ADDRESS: "0xc85a96ddF7f3B7fC48f00F86D507c0F970794D5a", //
    IDO_ADDRESS: '0x9d0d7821e8909dEFdBc3279F0f54dF47414780DC',
    ALPHA_ADDRESS: '0x483a9B8BB5d769857dc4b74D897207b2Cafd77F4',
  },
  97: {
    // StakingWarmup:0xD9e73A6B9C324c093e80caAC743E53D26Fe88678
    OHM_ADDRESS: "0x460E0B0574974c83d90c3fEd825c06524fCD7b50", //
    SOHM_ADDRESS: "0x2E6a2c085314Fab972358e63949c06e602F48d6F",
    STAKING_ADDRESS: "0x65a9650582116BDB03E3Dd0EE527d4CC7563C802",
    STAKING_HELPER_ADDRESS: "0xe3b7Be7B0c0F3550Dc1936f249F856D4054b1CAE",
    DISTRIBUTOR_ADDRESS: "0x3e905e708DF7D94257a32Db94b4827DEaC6AAacf",
    BONDINGCALC_ADDRESS: "0x844840F5621BdaC4e897f6FFA1287cFe90C3BFb5",
    TREASURY_ADDRESS: "0x81B7F8a0C7AeaA255756Dc655Bd38951260A5CC2",
    DAO_ADDRESS: '0x3BacEC6F69f118840345C59bc670B76dD7bFfC87',
    FEE_ADDRESS: '0x293A544E4d90B970B933584a443A519f1fe3c592',
    LP_ADDRESS: '0xac12a946a4978451A969080b7350b37503D3039C',
    REDEEM_HELPER_ADDRESS: "0x926A94C40A51CA49c16c46205FbA8cBba91239F0",
    MIGRATION_ADDRESS:'0x9Cc8476b96A6F9887E53e1E7e93703106FF04De1',
    TEAM_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    TEAM_AMOUNT_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    INVITE_ADDRESS: "0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4",
    USDT_ADDRESS: "0xc85a96ddF7f3B7fC48f00F86D507c0F970794D5a",
    IDO_ADDRESS: '0xA7803a19023fa4beF102d7F6D83Af059641f7E31',
    ALPHA_ADDRESS: '0x73f26773D6aBA4576474410cdE50A86EEf565478',
  }
};
