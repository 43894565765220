import { useMemo, useState, useEffect } from "react"
import { Row, Col } from 'antd'
import { useSelector, useDispatch } from "react-redux";
import { secondsUntilBlock, prettifySeconds2Hour } from "../../helpers";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { loadAppDetails } from "../../slices/AppSlice";
import { useWeb3Context } from "../../hooks/web3Context";
import { useTranslation } from 'react-i18next';
import './timeCount.scss'
import './timeCount.app.scss'

let n = 1

export default function TimeCount() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { provider, chainID } = useWeb3Context();
	const [coundown, setCoundown] = useState({ hour: '00', minute: '00', second: '00' })

  const SECONDS_TO_REFRESH = 60;
  const [secondsToRebase, setSecondsToRebase] = useState(0);
  const [rebaseString, setRebaseString] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });
  const endBlock = useSelector(state => {
    return state.app.endBlock;
  });
  function initializeTimer() {
    const seconds = secondsUntilBlock(currentBlock, endBlock);
    setSecondsToRebase(seconds);
    const prettified = prettifySeconds2Hour(seconds);
      setRebaseString(prettified !== "" ? JSON.stringify(prettified) : "Less than a minute");
  }

  useMemo(() => {
    if (currentBlock) {
      initializeTimer();
    }
  }, [currentBlock]);

  // useEffect(() => {
  //   let interval = null;
  //   if (secondsToRefresh > 0) {
  //     interval = setInterval(() => {
  //       setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
  //     }, 1000);
  //   } else {
  //     if (secondsToRebase < 0) {
  //       async function reload() {
  //         await dispatch(loadAppDetails({ networkID: chainID, provider: provider }));
  //       }
  //       reload();
  //       setRebaseString("");
  //     } else {
  //       clearInterval(interval);
  //       setSecondsToRebase(secondsToRebase => secondsToRebase - SECONDS_TO_REFRESH);
  //       setSecondsToRefresh(SECONDS_TO_REFRESH);
  //       const prettified = prettifySeconds2Hour(secondsToRebase);
  //       setRebaseString(prettified !== "" ? JSON.stringify(prettified) : "Less than a minute");
  //     }
  //   }
  //   return () => clearInterval(interval);
  // }, [secondsToRebase, secondsToRefresh]);

  useEffect(() => {
    let interval = null
    if(secondsToRebase) {
      interval = setInterval(() => {
        const seconds = secondsToRebase - n
        setCoundown(prettifySeconds2Hour(seconds))
        n = n + 1
      }, 1000)
    }
    return () => clearInterval(interval);
	}, [secondsToRebase, secondsToRefresh])

  return (
    <div className="time-container">
      <div className="flex flex-justify-content-center rebase-text">{t('stake.toNext')}</div>
      <Row justify="space-around" align="middle" className="countDown mt-10 font-weight-b">
        {/* <Row justify="center" align="middle" className="flex-direction-column">
          <Col className="time countItem-text">{coundown.day}</Col>
          <div className="time-item">Days</div>
        </Row>
        <span className="countItem-text countItem-text-symbol">:</span> */}
        <Row justify="center" align="middle" className="flex-column">
          <Col className="time countItem-text">{coundown.hour}</Col>
          <div className="time-item">HR</div>
        </Row>
        <Row justify="center" align="middle" className="flex-column">
          <Col className="time countItem-text">{coundown.minute}</Col>
          <div className="time-item">MIN</div>
        </Row>
        <Row justify="center" align="middle" className="flex-column">
          <Col className="time countItem-text">{coundown.second}</Col>
          <div className="time-item">SEC</div>
        </Row>
      </Row>
    </div>
  )
}
