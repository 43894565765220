import { ethers } from 'ethers'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { addresses } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { IJsonRPCError } from 'src/slices/interfaces';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from '.';
import { abi as aSOHMMigration } from "../abi/aSOHMMigration.json";
import { abi as IDOABI } from '../abi/ido.json'
import { abi as ERC20 } from '../config/abi/erc20.json'
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { error } from "../slices/MessagesSlice";
import MorganIcon from '../assets/morganIcon.png'
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useIdo() {

    const { t } = useTranslation()
    const dispatch = useDispatch()
	const [goal, setGoal] = useState(0)
    const [alphaBalance, setAlphaBalance] = useState('0')
    const [iceBalance, setIceBalance] = useState('0')
    const { address, provider, chainID } = useWeb3Context()
    const [isApproving, setApproving] = useState(false)
	const [allowance, setAllowance] = useState()
	const [white, setWhite] = useState(false)
	const [participated, setParticipated] = useState(false)
	const [alloction, setAllocation] = useState(0)

    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async () => {
            if (provider && chainID) {
                try {
                    
					const idoContract = new ethers.Contract(addresses[chainID].IDO_ADDRESS, IDOABI, provider)
                    
					const toTalAmount = await idoContract.toTalAmount()
					setGoal(toTalAmount)
					const alloction = await idoContract.saleAmount()
					setAllocation(alloction)
                    
                    if (address) {
                        const aiceContract = new ethers.Contract(addresses[chainID].USDT_ADDRESS, ierc20Abi, provider)
                        const alphaBalance = await aiceContract.balanceOf(address)
                        const tokendecimals = await aiceContract.decimals()
                        setAlphaBalance(ethers.utils.formatUnits(alphaBalance, tokendecimals))

                        const isApprove = await aiceContract.allowance(address, addresses[chainID].IDO_ADDRESS)
						setAllowance(isApprove)

						const white0 = await idoContract.whiteListed(address)
						const white1 = await idoContract.whiteListed1(address)
						setWhite(white0 || white1)

						const participated = await idoContract.boughtMOS(address)
						setParticipated(participated)
					}
                } catch (error) {
                    // console.log(error)
                }
            }
        },
        [address, provider, chainID]
    )

        const DoClaim = useCallback(
            async (amount) => {
                if (amount > 0) {
                    const valueInWei = ethers.utils.parseUnits(amount.toString(), "ether");
                    if (address && provider && chainID) {
                        const signer = provider.getSigner()
                        const idoContract = new ethers.Contract(addresses[chainID].IDO_ADDRESS, IDOABI, signer)
                        let tx;
                        try {
                            tx = await idoContract.purchaseaMOS(valueInWei)
                            await tx.wait()
                            return tx
                        } catch (e:any) {
                            if (e.data) {
                                openWarningNotification(e.data.message)
                            } else {
                                openWarningNotification(e.message)
                            }
                        } finally {
                            if (tx) {
                                initData().then()
                                addTokenToWallet(addresses[chainID].ALPHA_ADDRESS).then()
                                openSuccessNotification(t('common.success'))
                            }
                        }
                    }
                }
            },
            [address, provider, chainID],
        )

    const addTokenToWallet = async (tokenAddress:any) => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: tokenAddress,
                            symbol: 'alphaMOS',
                            decimals: 9,
                            image: MorganIcon,
                        },
                    },
                });
            } catch (error:any) {
                openWarningNotification(error.message)
            }
        }
    };

    const DoApprove = useCallback(
		async () => {
			if (address && provider && chainID) {
				const signer = provider.getSigner()
				const usdtContract = new ethers.Contract(addresses[chainID].USDT_ADDRESS, ERC20, signer)
				let tx;
				try {
					tx = await usdtContract.approve(addresses[chainID].IDO_ADDRESS, ethers.utils.parseEther("10000000").toString())
					await tx.wait()
					return tx
				} catch (e:any) {
					if (e.data) {
						openWarningNotification(e.data.message)
					} else {
						openWarningNotification(e.message)
					}
				} finally {
					if (tx) {
						initData().then()
						openSuccessNotification(t('tip.approveSucceed'))
					}
				}
			}
		},
		[address, provider, chainID],
	)

    const getTokenBalance = useCallback(
        async (tokenAddress) => {
            if (provider && address) {
                try {
                    const toeknContract = new ethers.Contract(tokenAddress, ERC20, provider)
                    const tokenBalance = await toeknContract.balanceOf(address)
                    return tokenBalance
                } catch (e) {
                //    console.log('error',e)
                }

            }
        },
        [address, provider, chainID]
    )

    // const handlerMigrate = useCallback(async () => {
    //     let tx;	// Migrate
    //     try {
    //         const signer = provider.getSigner();
    //         const migrationContract = new ethers.Contract(addresses[chainID].ASOHM_MIGRATION_ADDRESS, aSOHMMigration, signer)
    //         tx = await migrationContract.migrate()
    //         dispatch(
    //             fetchPendingTxns({ txnHash: tx.hash, text: "Claim_MOS", type: "Claim_MOS" }),
    //         );
    //         await tx.wait();
    //         return tx
    //     } catch (e: unknown) {
    //         const rpcError = e as IJsonRPCError;
    //         if (rpcError.data) {
    //             dispatch(error(rpcError.data.message))
    //         } else {
    //             dispatch(error(rpcError.message))
    //         }
    //     } finally {
    //         if (tx) {
    //             initData().then()
    //             await addTokenToWallet()
    //             dispatch(clearPendingTxn(tx.hash));
    //         }
    //     }
    // }, [address, provider, chainID])

    return { goal, white, participated, allowance, alloction, alphaBalance, iceBalance, isApproving, DoClaim, DoApprove, getTokenBalance }
}



